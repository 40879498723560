import styled, { createGlobalStyle } from "styled-components"

export const baseGutter = 120

const breakpointLarge = "1280px"

export const deviceSizes = {
  mobile: 375,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1280,
  desktopLarge: 1440,
}

export const device = {
  mobile: `(min-width: ${deviceSizes.mobile}px)`,
  tablet: `(min-width: ${deviceSizes.tablet}px)`,
  tabletLandscape: `(min-width: ${deviceSizes.tabletLandscape}px)`,
  desktop: `(min-width: ${deviceSizes.desktop}px)`,
  desktopLarge: `(min-width: ${deviceSizes.desktopLarge}px)`,
}

export const colors = {
  white: "#fff",
  black: "#231f20",
  darkGreen: "#267f68",
  darkGreenRgb: "38, 127, 104",
  green: "#60a88b",
  lightGreen: "#d9e8d9",
  grayGreen: "#bdd6ce",
  darkPink: "#e28686",
  pink: "#f0ddd9",
  lightPink: "#f2dddd",
}

export const bodyTextStyles = `
  font-family: "Work Sans";
  font-size: 18px;

  @media ${device.tabletLandscape} {
    font-size: 22px;
  }
`

export default createGlobalStyle`
    :root {
    --BASE_GUTTER: 20px;

    @media ${device.tabletLandscape} {
      --BASE_GUTTER: 40px;
    }
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    color: ${colors.white};
    scroll-behavior: smooth;

    padding-bottom: 70vh;
  }

  h1, h2, h3, ul, p{
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }

  a,
  a:hover,
  a:visited {
    text-decoration: none;
  }

  main {
    background-color: ${colors.green};
  }


  h1, h2, h3 {
    font-family: "Poppins";
  }

  h1 {
    font-size: 8vw;
    font-weight: 900;
    line-height: 1;
  }

  h3 {
    font-size: 40px;
    font-weight: 600;
  }

  ul {
    list-style: none;
  }

  button {
    border: none;
    cursor: pointer;
    border-radius: 0;
    outline: 0;
    -webkit-appearance: none;
    font-family: "Poppins";
  }

  .arrow-temp {
    transform: translate3d(35.8%, 32%, 0);
  }
  
  @media (min-width: ${breakpointLarge}){
    font-size: 22px;
  }
}
`

export const absoluteCenter = () => {
  return `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  `
}

export const themes = {
  start: {
    background: colors.lightGreen,
    text: colors.white,
    logo: colors.darkGreen,
  },
  about: {
    background: colors.lightGreen,
    text: colors.darkGreen,
  },
  dental: {
    background: colors.green,
    text: colors.white,
  },
  cosmetic: {
    background: colors.pink,
    text: colors.darkPink,
  },
  media: {
    background: colors.grayGreen,
    text: colors.darkGreen,
  },
  contact: {
    background: colors.darkPink,
    text: colors.lightPink,
  },
  pink: {
    background: colors.pink,
    text: colors.darkPink,
  },
}

export const centeredInMaxWidth = `
  max-width: ${deviceSizes.desktopLarge}px;
  margin: auto;
`

export const gridStyles = `
  display: flex;
  flex-wrap: wrap;

  @media ${device.tabletLandscape} {
    display: grid;
    grid-template-columns: repeat(12, minmax(20px, 1fr));
    gap: 0px 0px;
  }
`

export const leftColumn = `
  grid-column: 2 / span 6;
`

export const rightColumn = `
  grid-column: 6 / span 6;
`

export const SectionBody = styled.div<{ rightAlign?: boolean }>`
  position: relative;
  z-index: 20;

  ${props => (props.rightAlign ? rightColumn : leftColumn)};
  justify-self: ${props => (props.rightAlign ? "end" : "start")};

  > p {
    display: block;
    font-family: "Work Sans";

    position: relative;
    max-width: 700px;

    ${bodyTextStyles};
  }
`
