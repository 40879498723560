import React, { useState, useContext, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { GlobalStateContext } from "../context/GlobalContext"
import { colors, device } from "../globalStyles"
import { useStaticQuery, graphql } from "gatsby"

const animationDuration = 3000

const QuotesSection = styled.section`
  height: 70vh;
  background: ${colors.pink};
  color: ${colors.darkPink};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;
  padding: 20px;
`

const fadeInOut = keyframes`
    0% {
      opacity: 0
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0
    }
`

const StyledQuote = styled.div<{
  index: number
}>`
line-height: 1.1;
position: absolute;
opacity: 0; 
font-family: "Poppins";
font-weight: 400;

font-size: 18px;
max-width: 280px;

&:before {
  content: "\”";
  font-size: 36px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

  @media ${device.tabletLandscape} {
    font-size: 24px;
    max-width: 40vw;
  }

  &:first-of-type {
    left 10%;
    top: 40%;
  }

  &:nth-of-type(2) {
    right 10%;
    top: 70%;
  }

  &:nth-of-type(3) {
    right 10%;
    top: 40%;
    
    @media ${device.tabletLandscape} {
      right 2%;
    }
  }

  &:nth-of-type(4) {
    left: 5%;
    top: 30%;
  }

  &:nth-of-type(5) {
    left: 20%;
    top: 20%;
  }

  &:nth-of-type(6) {
    left: 12%;
    @media ${device.tabletLandscape} {
      left: 30%;
    }
    top: 50%;
  }

  &.active {
    animation-name: ${fadeInOut};
    animation-duration: ${animationDuration}ms;
  }
`
interface Quote {
  quote: string
}

interface SanityQuotes {
  allSanityQuote: {
    nodes: Quote[]
  }
}

export default () => {
  const { allSanityQuote }: SanityQuotes = useStaticQuery(graphql`
    query {
      allSanityQuote(sort: { fields: quote, order: ASC }) {
        nodes {
          quote
        }
      }
    }
  `)

  const { nodes: quotes } = allSanityQuote

  const [activeIndex, setActiveIndex] = useState(0)
  const { theme } = useContext(GlobalStateContext)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex => {
        const lastQuote = activeIndex === quotes.length - 1
        const newIndex = lastQuote ? 0 : activeIndex + 1
        return newIndex
      })
    }, animationDuration)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      {theme === "contact" ? (
        <QuotesSection>
          {quotes.map((quote, index) => {
            return (
              <StyledQuote
                className={activeIndex === index ? "active" : ""}
                index={index}
                key={`quote-${index}`}
              >
                {quote.quote}
              </StyledQuote>
            )
          })}
        </QuotesSection>
      ) : null}
    </>
  )
}
