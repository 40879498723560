import React, { ReactNode, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Quotes from "./quotes"
import { GlobalStateContext } from "../context/GlobalContext"
import GlobalStyles, { themes } from "../globalStyles"

interface LayoutInterface {
  children: ReactNode
}

const MainWrapper = styled.main<{ theme?: string }>`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  transition: background-color 0.2s linear, color 0.2s linear;
`

const Layout = ({ children }: LayoutInterface) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { theme } = useContext(GlobalStateContext)
  const themeColors = themes[theme]

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={themeColors}>
        <MainWrapper>{children}</MainWrapper>
        <Quotes />
      </ThemeProvider>
    </>
  )
}

export default Layout
